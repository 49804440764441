import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';

// Components
import Container from 'components/Container';
import ImagePromo from 'components/promos/ImagePromo';
import Footer from 'components/Footer';

import styles from './styles.styl';

const cx = cn.bind(styles);

const FooterLine = ({ promos }) => {
  const isVerticalSmallTablet = useSelector(state => state.responsive.isVerticalSmallTablet);

  return (
    <Container className={cx('FooterLine')}>
      {!isVerticalSmallTablet && (
        <div className={cx('FooterLine__promo')}>
          <ImagePromo
            className={cx('FooterLine__promoItem')}
            gtmId={'Image_bner_Bot1'}
            id={promos[0]}
          />
          <ImagePromo
            className={cx('FooterLine__promoItem')}
            gtmId={'Image_bner_Bot2'}
            id={promos[1]}
          />
        </div>
      )}

      <Footer className={cx('FooterLine__footer')} />
    </Container>
  );
};

FooterLine.propTypes = {
  promos: PropTypes.array,
};

FooterLine.defaultProps = {
  promos: [],
};

export default FooterLine;
