import React, { useEffect, memo } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Custom Hooks
import usePromoData from 'hooks/usePromoData';
import useTheme from 'hooks/useTheme';
import useAnalyticsBanner from '../../../hooks/useAnalyticsBanner';
// Components
import Image from 'components/Image';
import Container from 'components/Container';
import AppLink from 'components/AppLink';
import Video from 'components/Video';
import CustomPromoContent from 'components/promos/CustomPromo/CustomPromoContent';

import styles from './styles.styl';
import { Typography } from 'ui/Typography';

// eslint-disable-next-line react/prop-types
const BigImage = ({ url, type, bg_image, onClick }) => {
  const a =
    (type && type === 'video') ||
    bg_image.indexOf('.mp4') !== -1 ||
    bg_image.indexOf('.mov') !== -1;
  return (
    <AppLink
      href={url}
      targetBlank
      onClick={onClick}
      id="Big_Image_bner"
      className={styles.BrandingPromo__link}
    >
      {a ? (
        <Video
          className={styles.BrandingPromo__video}
          preload="auto"
          src={bg_image}
          autoPlay="autoplay"
          loop="loop"
          muted
        />
      ) : (
        <Image image={bg_image} alt={'Promo Banner'} className={styles.BrandingPromo__image} />
      )}
    </AppLink>
  );
};

const CUSTOM = {
  'big-image': BigImage,
  custom: CustomPromoContent,
};

const BrandingPromo = ({ id, type, className }) => {
  // for exclude promo bigBanner on About page header
  const { asPath } = useRouter();
  const isAboutPage = asPath.includes('/contact-us');
  //

  const { id: promoID, type: promoType, content, is_sponsored } = usePromoData(id, type) || {};
  const setTheme = useTheme().setTheme;
  const Custom =
    content && content.custom && content.custom.type ? CUSTOM[content.custom.type] : null;

  // Смена темы в брендинговом баннера
  useEffect(() => {
    if (promoType && promoType === 'branding') {
      setTheme({
        bgImage: content.bg_image,
        bgColor: content.bg_color,
      });
    }
  }, [content, promoType, setTheme]);

  const { handleClick, ref } = useAnalyticsBanner({
    banner_id: promoID,
    banner_type: promoType,
  });

  return (
    !isAboutPage && (
      <>
        <div className="BrndngPrmo_sklton26042024">
          <Container ref={ref} className={cn(className, styles.BrandingPromo__container)}>
            {promoType === 'big-image' ? (
              <div onClick={handleClick}>
                <BigImage {...content} />
                {is_sponsored && (
                  <Typography.Label level="4" className={styles.BrandingPromo__sponsoredImage}>
                    Sponsored Ad
                  </Typography.Label>
                )}
              </div>
            ) : (
              Custom && (
                <div onClick={handleClick}>
                  <Custom
                    className={cn('styles.BrandingPromo__custom', {
                      [styles[`BrandingPromo__custom--${content.custom.type}`]]: true,
                    })}
                    {...content.custom.content}
                  />
                </div>
              )
            )}
          </Container>
        </div>
        <style jsx>{`
          .BrndngPrmo_sklton26042024 {
            height: 196px;
            margin-bottom: 80px;
            background-color: transparent;
          }

          @media screen and (max-width: 1439px) {
            .BrndngPrmo_sklton26042024 {
              height: 148px;
              margin-bottom: 40px;
            }
          }

          @media screen and (max-width: 1079px) {
            .BrndngPrmo_sklton26042024 {
              height: 138px;
            }
          }

          @media screen and (max-width: 971px) {
            .BrndngPrmo_sklton26042024 {
              height: 146px;
            }
          }

          @media screen and (max-width: 822px) {
            .BrndngPrmo_sklton26042024 {
              height: 122px;
            }
          }

          @media screen and (max-width: 674px) {
            .BrndngPrmo_sklton26042024 {
              height: 98px;
            }
          }

          @media screen and (max-width: 567px) {
            .BrndngPrmo_sklton26042024 {
              height: 87px;
              margin-bottom: 20px;
            }
          }

          @media screen and (max-width: 376px) {
            .BrndngPrmo_sklton26042024 {
              height: 56px;
              margin-bottom: 20px;
            }
          }
        `}</style>
      </>
    )
  );
};

BrandingPromo.defaultProps = {
  id: null,
  type: 'big-image',
  inHead: false,
  className: '',
};

BigImage.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
  bg_image: PropTypes.any,
};

BrandingPromo.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  inHead: PropTypes.bool,
  className: PropTypes.string,
};

export default memo(BrandingPromo);
